import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import NavbarTop from "../components/navbar/NavbarTop";
import NavbarVertical from "../components/navbar/NavbarVertical";
import Footer from "../components/footer/Footer";
import loadable from "@loadable/component";
import AppContext from "../context/Context";
import useScript from "../hooks/useScript";

const DashboardRoutes = loadable(() => import("./DashboardRoutes"));

const DashboardLayout = ({ location }) => {
	const { isFluid } = useContext(AppContext);

	useEffect(() => {
		DashboardRoutes.preload();
	}, []);

	// 네이버 프리미엄 로그분석 공통 적용 스크립트
	useScript(
		"body",
		false,
		null,
		`
    if (!wcs_add) var wcs_add={};
    wcs_add["wa"] = "s_4c8a690f092e";
    if (!_nasa) var _nasa={};
    wcs.inflow();
    wcs_do(_nasa);
    var _nasa={};  // 초기화구문
    `,
		location?.pathname
	);

	return (
		<div
			id="container"
			className={isFluid ? "container-fluid" : "container"}
		>
			<NavbarVertical />
			<div className="content">
				<NavbarTop />
				<DashboardRoutes />
				<Footer />
			</div>
		</div>
	);
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;
