import React from 'react';
import {Button, Media, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const PopUpModal = ({content, toggle, isOpen}) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} size='md' centered>
            <ModalHeader className="border-200">
                <Media className="align-items-center">
                    <div className={'icon-item shadow-none bg-soft-' + content.color}>
                        <FontAwesomeIcon
                            icon={content.icon}
                            transform={content.iconTransform}
                            className={'text-' + content.color}
                        />
                    </div>
                    <Media body className="ml-2 word-break-keep-all">
                        {content.title}
                    </Media>
                </Media>
            </ModalHeader>
            <ModalBody className="border-top border-200 px-5">
                <div className='text-center text-800 word-break-keep-all'>{content.body}</div>
            </ModalBody>
            <ModalFooter className="border-200">
                <Button onClick={toggle}>
                    닫기
                </Button>
                <Button onClick={() => {
                    toggle()
                    if (!!content.confirmHandler) {
                        content.confirmHandler();
                    }
                }} color={content.color}>
                    {content.confirmText}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default PopUpModal;