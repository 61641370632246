import React from 'react';
import {Button, Media, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import Avatar from "../../common/Avatar";
import {Link} from "react-router-dom";
import {appUrls} from "../../../urls";
import {numberToKorean, truncateNumber} from "../../../helpers/utils";

const InfluencerItem = ({image_url, fans_count, nickname, price_tag, pk, isVideo}) => {
    return (
        <Row className='py-3'>
            <Col className='col-auto my-auto'>
                <Avatar
                    src={image_url}
                    rounded="circle"
                    className='d-block'
                    size="3xl"
                />
            </Col>
            <Col className='my-auto px-0'>
                <div>
                    <Link className="h6 text-900 fs-sm-1 fs-1 text-decoration-none"
                          to={appUrls({pk}).XHSInfluencerDetail}>
                        {nickname}
                    </Link>
                </div>
                <div className="h6 fs-sm-0 fs-0 mb-0">
                    {truncateNumber(fans_count)}
                    <span className="fs-sm--1 fs--1 ml-1 text-700">
                        팔로워
                    </span>
                </div>
            </Col>
            <Col className='my-auto col-auto text-right'>
                <span className='fs--1'>{isVideo ? '영상' : '이미지'}</span>
                <div className='text-right fs-sm-1 fs-0 font-weight-medium text-800 text-nowrap'>
                    {numberToKorean(price_tag)}
                </div>
            </Col>
        </Row>
    )
};

const ShoppingCartModal = ({isOpen, toggle, content}) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} size='md' centered>
            <ModalHeader className="border-200" toggle={toggle}>
                <Media className="align-items-center">
                    <div
                        className={classNames('icon-item shadow-none', {
                            'bg-soft-danger': content.removed,
                            'bg-soft-success': !content.removed
                        })}
                    >
                        <FontAwesomeIcon
                            icon={classNames({
                                'exclamation': content.removed,
                                'users': !content.removed
                            })}
                            className={classNames({
                                'text-warning': content.removed,
                                'text-success': !content.removed
                            })}
                        />
                    </div>

                    <Media body className="ml-2 word-break-keep-all">
                        {content.removed
                            ? '인플루언서를 캠페인 라인업에서 삭제했습니다.'
                            : '인플루언서를 캠페인 라인업에 추가했습니다.'
                        }
                    </Media>
                </Media>
            </ModalHeader>
            <ModalBody>
                <p className='font-weight-medium text-900'>
                    {content.removed ? '삭제' : '추가'}한 인플루언서
                </p>
                <div className='container'>
                    <InfluencerItem pk={content.this.pk}
                                    image_url={content.this.image_url}
                                    nickname={content.this.nickname}
                                    fans_count={content.this.fans_count}
                                    price_tag={content.this.price_tag}
                                    isVideo={content.this.is_video}
                    />
                </div>
            </ModalBody>
            <ModalBody className="border-top border-200">
                <div className='font-weight-medium text-900 d-flex justify-content-between mb-2'>
                    <div>
                        현재 캠페인 라인업 (총 {content.current.length}명)
                    </div>
                    <div className='mr-3'>
                        <span className='fs--1 text-800 mr-2'>합계</span>
                        <span>
                            {numberToKorean(content.current.reduce((accum, curr) => accum + curr.price_tag, 0))}
                        </span>
                    </div>
                </div>
                <div className='container' style={{height: '15rem', overflowY: 'scroll'}}>
                    {content.current.map((influencer, index) =>
                        <InfluencerItem pk={influencer.pk}
                                        key={index}
                                        image_url={influencer.image_url}
                                        nickname={influencer.nickname}
                                        fans_count={influencer.fans_count}
                                        price_tag={influencer.price_tag}
                                        isVideo={influencer.is_video}
                        />)}
                </div>
            </ModalBody>
            <ModalFooter className="border-200">
                <Button tag={Link} color="primary" to={appUrls().XHSCampaignLineup} className="mr-2">
                    자세히 보기
                </Button>
                <Button onClick={toggle}>
                    닫기
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ShoppingCartModal;