import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Avatar from '../common/Avatar';
import team3 from '../../assets/img/team/3.jpg';
import AppContext, {AuthContext} from "../../context/Context";
import {logout} from "../../actions/auth";
import withRedirect from "../../hoc/withRedirect";
import {appUrls} from "../../urls";
import classNames from 'classnames';

const ProfileDropdown = ({setRedirectUrl, setRedirect, landing}) => {
    const {authState: {user}, authDispatch} = useContext(AuthContext);
    const {isDark, setIsDark} = useContext(AppContext);
    const [logoutLoading, setLogoutLoading] = useState(false);

    const handleLogout = () => {
        setLogoutLoading(true);
        // 홈에서 로그아웃한 경우가 아니면 리다이렉트할 필요가 없다.
        if (!landing) {
            setRedirectUrl(appUrls().home);
            setRedirect(true);
        }

        // 중간에 cancel해버리면 로그아웃이 실패하므로 cleanup하지 않는다.
        logout(null, authDispatch).then(() => {
            setLogoutLoading(false);
        });
    };

    return (
        <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav className={classNames("pr-0 py-0", {
                "pl-lg-2 pl-0 mt-lg-0 mt-3 mb-lg-0 mb-2": landing,
                "pl-2": !landing
            })} style={{lineHeight: 0}}>
                <Avatar
                    name={user.profile.manager_name.slice(1, 3)}
                    className={logoutLoading ? 'opacity-50' : ''}
                    style={{marginTop: '-0.3rem'}}
                    isExact
                />
            </DropdownToggle>
            <DropdownMenu right className="dropdown-menu-card mt-1">
                <div className="bg-white rounded-soft py-2">
                    {/*<DropdownItem className="font-weight-bold" style={{color: isDark ? '#ffc206' : '#271639'}} onClick={() => setIsDark(!isDark)}>*/}
                    {/*    <FontAwesomeIcon icon={isDark ? 'sun' : 'moon'} className="mr-2"/>*/}
                    {/*    <span>{isDark ? '라이트 모드' : '다크 모드'}</span>*/}
                    {/*</DropdownItem>*/}
                    {/*<DropdownItem divider/>*/}
                    {/*<DropdownItem href="#!">Set status</DropdownItem>*/}
                    {/*<DropdownItem tag={Link} to="/pages/profile">*/}
                    {/*    Profile &amp; account*/}
                    {/*</DropdownItem>*/}
                    {/*<DropdownItem href="#!">Feedback</DropdownItem>*/}
                    {/*<DropdownItem divider/>*/}
                    {/*<DropdownItem tag={Link} to="/pages/settings">*/}
                    {/*    설정*/}
                    {/*</DropdownItem>*/}
                    <DropdownItem onClick={handleLogout}>
                        로그아웃
                    </DropdownItem>
                </div>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
};

export default withRedirect(ProfileDropdown);
