import {useEffect} from 'react';
import PropTypes from "prop-types";
import Flex from "../components/common/Flex";
import {isDev} from "../helpers/utils";

const useScript = (position, async = false, url = null, innerScript = null, path=null) => {
    useEffect(() => {
        const placement = document.querySelector(position);
        const script = document.createElement('script');

        script.async = async;
        script.type = "text/javascript"

        isDev() && console.log(path, '에서', innerScript);

        if (url) {
            script.src = url;
        }

        if (innerScript) {
            const scriptText = document.createTextNode(innerScript);
            script.appendChild(scriptText);
        }

        placement.appendChild(script);

        return () => {
            isDev() && console.log(innerScript, '삭제');
            placement.removeChild(script);
        };
    }, path ? [url, position, innerScript, path] : [url, position, innerScript]);
};

useScript.propTypes = {
    url: PropTypes.string,
    position: PropTypes.oneOf(['head', 'body']),
    async: PropTypes.bool,
    innerScript: PropTypes.string,
};

export default useScript;