import React, {Fragment} from 'react';
import Lottie from "react-lottie";
import animationData from "../../assets/lottie/emoji_yellow";

const NotFoundEmoji = ({title, body}) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <Fragment>
            <div className='mt-80 pb-2'>
                <Lottie options={defaultOptions} width={150} height={150}/>
            </div>
            <p className='fs-1 font-weight-medium text-900 text-center mb-2'>
                {title}
            </p>
            <p className='text-800 text-center mb-4'>
                {body}
            </p>
        </Fragment>
    );
};

export default NotFoundEmoji;