import React, {useContext, useState, Fragment} from 'react';
import {Card, CardBody, CardFooter, CardHeader, Form, Nav, NavItem, NavLink} from 'reactstrap';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useForm} from 'react-hook-form';
import BasicUserForm from './BasicUserForm';
import AdvanceUserForm from './AdvanceUserForm';
import BillingUserForm from './BillingUserForm';
import Success from './Success';
import AppContext, {AuthContext, AuthWizardContext} from '../../../context/Context';

import WizardModal from './WizardModal';
import ButtonIcon from '../../common/ButtonIcon';
import {register as authRegister} from '../../../actions/auth'
import axios from 'axios';

const UserForm = () => {
    const {isRTL} = useContext(AppContext);
    const {user, setUser, step, setStep} = useContext(AuthWizardContext);
    const {authDispatch} = useContext(AuthContext);
    const {register, handleSubmit, errors, watch} = useForm({mode: 'onSubmit', reValidateMode: 'onBlur'});

    const onSubmitData = data => {
        if (step === 2) {
            handleFinalSubmit();
            setStep(step + 1);
        } else {
            setUser({...user, ...data});
            setStep(step + 1);
        }
    };

    const handleFinalSubmit = () => {
        // 여기서 user state의 데이터를 서버로 보내서 회원가입을 처리한다.
        // 회원가입 후 받은 토큰으로 로그인한다. (단, inactive 계정이므로 바로 401에러 발생해서 token이 제거될 것.)
        const body = {
            username: user.username,
            email: user.email,
            password: user.password,
            profile: {
                manager_name: user.manager_name,
                company_name: user.company_name,
                phone_number: user.phone_number,
                marketing_agreed: user.marketing_agreed
                // grade는 보안상 default='public'으로 처리한다.
            }
        };
        const source = axios.CancelToken.source();
        authRegister(body)(source, authDispatch);  // body로 회원가입 요청을 보낸다.
        return () => {
            source.cancel()
        };
    };

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const handleBackStep = targetStep => {
        if (step !== 4) {
            if (targetStep < step) {
                setStep(targetStep);
            }
        } else {
            toggle();
        }
    };

    return (
        <Fragment>
            <WizardModal toggle={toggle} modal={modal} setModal={setModal}/>
            <Card tag={Form} onSubmit={handleSubmit(onSubmitData)} className="theme-wizard">
                <CardHeader className="bg-light">
                    <Nav className="justify-content-center">
                        <NavItem>
                            <NavLink
                                className={classNames('font-weight-semi-bold', {
                                    'done cursor-pointer': step > 1,
                                    active: step === 1
                                })}
                                onClick={() => handleBackStep(1)}
                            >
                                  <span className="nav-item-circle-parent">
                                    <span className="nav-item-circle">
                                      <FontAwesomeIcon icon="lock"/>
                                    </span>
                                  </span>
                                <span className="d-none d-md-block mt-1 fs--1">계정</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classNames('font-weight-semi-bold', {
                                    'done  cursor-pointer': step > 2,
                                    active: step === 2
                                })}
                                onClick={() => handleBackStep(2)}
                            >
                                <span className="nav-item-circle-parent">
                                  <span className="nav-item-circle">
                                    <FontAwesomeIcon icon="user"/>
                                  </span>
                                </span>
                                <span className="d-none d-md-block mt-1 fs--1">회원 정보</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classNames('font-weight-semi-bold', {
                                    'done  cursor-pointer': step > 2
                                })}
                            >
                                <span className="nav-item-circle-parent">
                                  <span className="nav-item-circle">
                                    <FontAwesomeIcon icon="thumbs-up"/>
                                  </span>
                                </span>
                                <span className="d-none d-md-block mt-1 fs--1">완료</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </CardHeader>
                <CardBody className="fs--1 font-weight-normal px-md-6 pt-4 pb-3">
                    {step === 1 && <BasicUserForm register={register} errors={errors} watch={watch}/>}
                    {step === 2 && <AdvanceUserForm register={register} errors={errors}/>}
                    {step === 3 && <Success/>}
                </CardBody>
                <CardFooter className={classNames('px-md-6 bg-light', {'d-none': step === 3, ' d-flex': step < 3})}>
                    <ButtonIcon
                        color="link"
                        icon={isRTL ? 'chevron-right' : 'chevron-left'}
                        iconAlign="left"
                        transform="down-1 shrink-4"
                        className={classNames('px-0 font-weight-semi-bold', {'d-none': step === 1})}
                        onClick={() => {
                            setStep(step - 1);
                        }}
                    >
                        이전
                    </ButtonIcon>

                    <ButtonIcon
                        color="primary"
                        className="ml-auto px-5"
                        type="submit"
                        icon={isRTL ? 'chevron-left' : 'chevron-right'}
                        iconAlign="right"
                        transform="down-1 shrink-4"
                    >
                        다음
                    </ButtonIcon>
                </CardFooter>
            </Card>
        </Fragment>
    );
};

export default UserForm;
