import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import logo_gradient from '../../assets/img/logos/findy/findy_gradient.svg'
import logo_white from '../../assets/img/logos/findy/findy_white.svg'

const Logo = ({ at, width, color, className, ...rest }) => {
  return (
    <Link
      to="/"
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center font-weight-extra-bold fs-5 mb-4': at === 'auth'
          },
          className
        )}
      >
        <img className="mr-2 h-100" src={color === 'white' ? logo_white : logo_gradient} alt="Logo" width={width}/>
      </div>
    </Link>
  );
};

Logo.defaultProps = { at: 'auth', width: 120 };

export default Logo;
