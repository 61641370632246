import React, {Fragment, useContext, useEffect} from 'react';
import {Row, Col, Button} from 'reactstrap';

import Lottie from 'react-lottie';
import animationData from '../../../assets/lottie/iconmotion_envelope.json';
import {AuthWizardContext} from '../../../context/Context';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {appUrls} from "../../../urls";
import useScript from "../../../hooks/useScript";
import ReactPixel from "react-facebook-pixel";

const Success = () => {
    const {setStep, setUser, user} = useContext(AuthWizardContext);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    // 네이버 로그분석 전환스크립트 (회원가입 - 1원)
    useScript('head', null, null, `
    var _nasa={};
    _nasa["cnv"] = wcs.cnv("2","1");
    `)

    // 네이버 프리미엄 로그분석 공통 적용 스크립트
    useScript('body', false, null, `
    if (!wcs_add) var wcs_add={};
    wcs_add["wa"] = "s_4c8a690f092e";
    if (!_nasa) var _nasa={};
    wcs.inflow();
    wcs_do(_nasa);
    var _nasa={};  // 초기화구문
    `, null);

    useEffect(() => {
        ReactPixel.track('CompleteRegistration');
    }, []);

    return (
        <Fragment>
            <Row>
                <Col className="text-center">
                    <div className='lottie-wrapper'>
                        <Lottie options={defaultOptions} width={300} height={300}/>
                    </div>
                    <h3>인증 메일이 발송되었습니다.</h3>
                    <p className="fs-0 word-break-keep-all">
                        <strong>{user.email}</strong>로 인증 메일을 발송했습니다.
                        <br/>
                        보내드린 링크를 클릭하여 이메일을 인증해주세요. 인증 후 48시간동안 파인디의 모든 기능을 체험해보실 수 있습니다.
                    </p>

                    <Button tag={Link} color="primary" size="sm" className="my-3" to={appUrls().home}>
                        <FontAwesomeIcon icon="chevron-left" transform="shrink-4 down-1" className="mr-1"/>
                        메인 화면
                    </Button>
                </Col>
            </Row>
        </Fragment>
    );
};

export default Success;
