import React, {useReducer, useEffect, useState, useContext} from 'react';
import AppContext, {AuthContext} from '../../context/Context';
import {authReducer} from "../../reducers/authReducer";
import {loadUser} from "../../actions/auth";
import axios from "axios";

const AuthProvider = ({children}) => {
    // 여기서 dispatch를 생성하고 Context 값으로 넘겨준다.
    // 이제 하위 컴포넌트에서 auth.js의 함수를 실행하면서 dispatch를 인자로 넘겨줄 수 있다.
    // auth.js의 함수는 dispatch를 받아서 토큰 및 상태를 업데이트하고 필요한 AJAX request를 진행한다.
    const [authState, authDispatch] = useReducer(authReducer, {
        isLoading: false,
        isAuthenticated: false,
        user: null,
        remember: true,
        token: localStorage.getItem('token') || sessionStorage.getItem('token')
    });
    const {setCartCount} = useContext(AppContext);

    const isAdmin = () => {
        return authState.user?.profile.grade === 'staff'
    };

    useEffect(() => {
        const source = axios.CancelToken.source();
        // token이 없으면 요청을 보내도 어차피 401 뜨기때문에 token이 있을때만 요청을 보낸다.
        authState.token === null || loadUser(source, authDispatch).then(data => {
            setCartCount(data?.profile.cart_count)
        });
        return () => {
            source.cancel()
        };
    }, []);

    const value = {
        authState,
        authDispatch,
        isAdmin
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
