import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Collapse, CustomInput, Nav, Navbar} from 'reactstrap';
import Scrollbar from 'react-scrollbars-custom';
import Logo from './Logo';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton';
import AppContext from '../../context/Context';
import Flex from '../common/Flex';
import routes from '../../routes';
import {navbarBreakPoint} from '../../config';

const NavbarVertical = () => {
    const navBarRef = useRef(null);

    const {
        showBurgerMenu,
        isNavbarVerticalCollapsed,
        setIsNavbarVerticalCollapsed
    } = useContext(AppContext);

    const [mouseEntered, setMouseEntered] = useState(false);

    const HTMLClassList = document.getElementsByTagName('html')[0].classList;
    //Control Component did mount and unmount of hover effect
    if (isNavbarVerticalCollapsed) {
        HTMLClassList.add('navbar-vertical-collapsed');
    }

    useEffect(() => {
        return () => {
            HTMLClassList.remove('navbar-vertical-collapsed-hover');
        };
    }, [isNavbarVerticalCollapsed, HTMLClassList]);

    //Control mouseEnter event
    const handleMouseEnter = () => {
        if (isNavbarVerticalCollapsed) {
            setMouseEntered(true);
            HTMLClassList.add('navbar-vertical-collapsed-hover');
        }
    };

    const handleMouseLeave = () => {
        HTMLClassList.remove('navbar-vertical-collapsed-hover');
        setMouseEntered(false);
    };

    return (
        <Navbar expand={navbarBreakPoint} className="navbar-vertical navbar-glass" light
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
        >
            <Flex align="center">
                <ToggleButton
                    isNavbarVerticalCollapsed={isNavbarVerticalCollapsed}
                    setIsNavbarVerticalCollapsed={setIsNavbarVerticalCollapsed}
                />
                <Logo at="navbar-vertical" width={80}/>
            </Flex>

            <Collapse
                navbar
                isOpen={showBurgerMenu}
                className={mouseEntered && 'bg-200'}
                innerRef={navBarRef}
            >
                <Scrollbar
                    style={{height: 'calc(100vh - 64px)', display: 'block'}}
                    trackYProps={{
                        renderer(props) {
                            const {elementRef, ...restProps} = props;
                            return <span {...restProps} ref={elementRef} className="TrackY"/>;
                        }
                    }}
                >
                    <Nav navbar vertical>
                        <NavbarVerticalMenu routes={routes}/>
                    </Nav>
                </Scrollbar>
            </Collapse>
        </Navbar>
    );
};

export default NavbarVertical;
