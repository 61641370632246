import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Badge} from 'reactstrap';
import Flex from '../common/Flex';


const NavbarVerticalMenuItem = ({route}) => {
    return (
        <Flex align="center">
            {route.icon && (
                <span className="nav-link-icon">
                    <FontAwesomeIcon icon={route.icon} fixedWidth/>
                  </span>
            )}
            <span className="nav-link-text">{route.name}</span>
            {!!route.badge && (
                <Badge color={route.badge.color || 'soft-success'} pill className="ml-2">
                    {route.badge.text}
                </Badge>
            )}
        </Flex>
    );
};

export default React.memo(NavbarVerticalMenuItem);
