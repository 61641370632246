import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AuthProvider from "./components/findy-auth/AuthProvider";
import Main from "./Main";
import './helpers/initFA';

ReactDOM.render(
    <Main>
        <AuthProvider>
            <App/>
        </AuthProvider>
    </Main>,
    document.getElementById('main')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
