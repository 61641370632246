import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import AppContext from './context/Context';
import toggleStylesheet from './helpers/toggleStylesheet';
import AuthProvider from "./components/findy-auth/AuthProvider";
import {getItemFromStore, isDev, setItemToStore, themeColors} from './helpers/utils';
import ShoppingCartModal from "./components/influencer/shopping-cart/ShoppingCartModal";
import PopUpModal from "./components/common/PopUpModal";
import useScript from "./hooks/useScript";
import ReactPixel from 'react-facebook-pixel'

const Main = props => {
    const [isFluid, setIsFluid] = useState(getItemFromStore('isFluid', false));
    const [isRTL, setIsRTL] = useState(getItemFromStore('isRTL', false));
    const [isDark, setIsDark] = useState(getItemFromStore('isDark', false));
    const [isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed] = useState(
        getItemFromStore('isNavbarVerticalCollapsed', true)
    );
    const [currency, setCurrency] = useState('￥');
    const [showBurgerMenu, setShowBurgerMenu] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [cartCount, setCartCount] = useState(0);

    // PopUpModal 관련
    const [showModal, setShowModal] = useState(false);
    const [popUpContent, setPopUpContent] = useState({
        title: 'Title',
        body: 'Body',
        color: 'primary',
        icon: 'exclamation',
        confirmText: '확인',
        confirmHandler: () => {
        }
    });
    const [popUpLoading, setPopUpLoading] = useState(false);
    const togglePopUp = () => {
        setShowModal(!showModal);
    };

    const value = {
        isFluid,
        setIsFluid,
        isRTL,
        setIsRTL,
        isDark,
        setIsDark,
        showBurgerMenu,
        setShowBurgerMenu,
        isNavbarVerticalCollapsed,
        setIsNavbarVerticalCollapsed,
        currency,
        setCurrency,
        cartCount,
        setCartCount,
        togglePopUp,
        setPopUpContent,
        popUpLoading,
        setPopUpLoading,
    };

    const setStylesheetMode = mode => {
        setIsLoaded(false);
        setItemToStore(mode, value[mode]);
        toggleStylesheet({isRTL, isDark}, () => setIsLoaded(true));
    };

    useEffect(() => {
        setStylesheetMode('isFluid');
        // eslint-disable-next-line
    }, [isFluid]);

    useEffect(() => {
        setStylesheetMode('isRTL');
        // eslint-disable-next-line
    }, [isRTL]);

    useEffect(() => {
        setStylesheetMode('isDark');
        // eslint-disable-next-line
    }, [isDark]);

    useEffect(() => {
        setItemToStore('isNavbarVerticalCollapsed', isNavbarVerticalCollapsed);
        // eslint-disable-next-line
    }, [isNavbarVerticalCollapsed]);

    useScript('body', false, null, `(function() {
    var w = window;
    if (w.ChannelIO) {
      return (window.console.error || window.console.log || function(){})('ChannelIO script included twice.');
    }
    var d = window.document;
    var ch = function() {
      ch.c(arguments);
    };
    ch.q = [];
    ch.c = function(args) {
      ch.q.push(args);
    };
    w.ChannelIO = ch;
    function l() {
      if (w.ChannelIOInitialized) {
        return;
      }
      w.ChannelIOInitialized = true;
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
      s.charset = 'UTF-8';
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    }
    if (document.readyState === 'complete') {
      l();
    } else if (window.attachEvent) {
      window.attachEvent('onload', l);
    } else {
      window.addEventListener('DOMContentLoaded', l, false);
      window.addEventListener('load', l, false);
    }
  })();
  ChannelIO('boot', {
    "pluginKey": "c0901bce-0409-4ff3-8953-c0b4d23639d8", //please fill with your plugin key
  });`)

    const options = {
        autoConfig: true, // set pixel's autoConfig
        debug: isDev(), // enable logs
    };

    ReactPixel.init('2482318115199196', null, options);
    ReactPixel.pageView()

    if (!isLoaded) {
        toggleStylesheet({isRTL, isDark}, () => setIsLoaded(true));

        return (
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    backgroundColor: isDark ? themeColors.dark : themeColors.light
                }}
            />
        );
    }

    return (
        <AppContext.Provider value={value}>
            {props.children}
            <PopUpModal isOpen={showModal} toggle={togglePopUp} content={popUpContent}/>
        </AppContext.Provider>
    )
};

Main.propTypes = {children: PropTypes.node};

export default Main;
