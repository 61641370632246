import React, {Fragment} from 'react';
import WizardInput from './WizardInput';
import {Col, CustomInput, Row} from 'reactstrap';
import axios from "axios";
import {xiaohongshuUrls} from "../../../urls";

const BasicUserForm = ({register, errors, watch}) => {
    const validateUnique = async (field, value) => {
        const res = await axios.get(xiaohongshuUrls().validate_unique, {params: {field: field, value: value}});
        return res.data.success;
    };

    const validatePassword = async (password) => {
        const res = await axios.get(xiaohongshuUrls().validate_password, {params: {password: password}});
        return res.data;
    };

    return (
        <Fragment>
            <WizardInput
                label="아이디"
                placeholder="아이디"
                name="username"
                id="username"
                innerRef={register({
                    required: '필수 입력 항목입니다.',
                    pattern: {
                        value: /[\dA-Za-z@.+\-_ㄱ-ㅎㅏ-ㅣ가-힣]/,
                        message: '한글, 영문, 숫자, @/./+/-/_만 입력할 수 있습니다.'
                    },
                    maxLength: {
                        value: 150,
                        message: '150자 이하여야 합니다.'
                    },
                    validate: async value => await validateUnique('username', value) || '이미 존재하는 아이디입니다.'
                })}
                errors={errors}
            />
            <WizardInput
                label="이메일"
                placeholder="이메일"
                id="email"
                name="email"
                innerRef={register({
                    required: '필수 입력 항목입니다.',
                    pattern: {
                        value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        message: '유효한 이메일 주소가 아닙니다.'
                    },
                    validate: async value => await validateUnique('email', value) || '이미 존재하는 이메일입니다.'
                })}
                errors={errors}
            />
            <Row form>
                <Col>
                    <WizardInput
                        type="password"
                        label="비밀번호"
                        placeholder="비밀번호"
                        id="password"
                        name="password"
                        innerRef={register({
                            required: '필수 입력 항목입니다.',
                            validate: async value => {
                                const res = await validatePassword(value);
                                return res.success || res.message
                            }
                        })}
                        errors={errors}
                        autoComplete='new-password'
                    />
                </Col>
                <Col>
                    <WizardInput
                        type="password"
                        label="비밀번호 확인"
                        placeholder="비밀번호 확인"
                        id="confirmPassword"
                        name="confirmPassword"
                        innerRef={register({
                            required: '필수 입력 항목입니다.',
                            validate: value => value === watch('password') || '비밀번호가 일치하지 않습니다.'
                        })}
                        errors={errors}
                        autoComplete='new-password'
                    />
                </Col>
            </Row>
            <WizardInput
                type="checkbox"
                id="agreeToTerms"
                formGroupClassName='mb-0'
                tag={CustomInput}
                label={
                    <Fragment>
                        <a href="#!"> 이용 약관</a> 및 <a href="#!"> 개인정보 취급방침</a> 동의
                    </Fragment>
                }
                name="agreeToTerms"
                innerRef={register({
                    required: '필수 동의 항목입니다.'
                })}
                errors={errors}
            />
            <WizardInput
                type="checkbox"
                id="marketing_agreed"
                tag={CustomInput}
                label={
                    <Fragment>
                        <a href="#!">마케팅 정보 수신</a> 동의
                    </Fragment>
                }
                name="marketing_agreed"
                innerRef={register({
                    required: false
                })}
                errors={errors}
            />
        </Fragment>
    );
};

export default BasicUserForm;
