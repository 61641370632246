import React from 'react';
import {Col, Row} from 'reactstrap';
import UserForm from './UserForm';
import Section from '../../common/Section';
import Logo from '../../navbar/Logo';
import AuthWizardProvider from './AuthWizardProvider';
import useScript from "../../../hooks/useScript";

const WizardLayout = ({location}) => {
    return (
        <Section className="py-0">
            <Row className="flex-center min-vh-100 py-6">
                <Col sm={10} lg={7} className="col-xxl-5">
                    <Logo/>
                    <AuthWizardProvider>
                        <UserForm/>
                    </AuthWizardProvider>
                </Col>
            </Row>
        </Section>
    );
};

export default WizardLayout;
