import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faChartPie,
    faCopy,
    faSearch,
    faHome,
    faBell,
    faPlay,
    faChevronRight,
    faChevronLeft,
    faChevronUp,
    faChevronDown,
    faExchangeAlt,
    faAngleRight,
    faEllipsisH,
    faCrown,
    faListAlt,
    faCheck,
    faBan,
    faStream,
    faArrowsAltV,
    faSort,
    faSortAlphaDown,
    faSortAlphaUp,
    faSortNumericDown,
    faSortNumericUp,
    faSortAmountDown,
    faSortAmountUp,
    faCheckCircle,
    faUserCircle,
    faQuestionCircle,
    faLock,
    faCircle,
    faHeart,
    faShareAlt,
    faCaretRight,
    faArrowDown,
    faArrowUp,
    faPrint,
    faDollarSign,
    faLink,
    faPlus,
    faMinus,
    faUser,
    faPencilAlt,
    faCamera,
    faTimesCircle,
    faSignInAlt,
    faBook,
    faPuzzlePiece,
    faTrash,
    faAlignLeft,
    faAlignRight,
    faPlug,
    faTimes,
    faFilter,
    faExternalLinkAlt,
    faCartPlus,
    faShoppingCart,
    faStar,
    faStarHalfAlt,
    faArrowLeft,
    faArrowRight,
    faArchive,
    faEnvelope,
    faClock,
    faReply,
    faLocationArrow,
    faCog,
    faPaperclip,
    faImage,
    faEllipsisV,
    faEnvelopeOpen,
    faRedo,
    faSlidersH,
    faTrashAlt,
    faFileArchive,
    faFilePdf,
    faFileAlt,
    faEye,
    faCaretUp,
    faCaretDown,
    faCodeBranch,
    faExclamationTriangle,
    faListUl,
    faTh,
    faGift,
    faUsers,
    faGlobeAmericas,
    faPlusSquare,
    faUserPlus,
    faUserCheck,
    faCircleNotch,
    faExclamation,
    faThumbsUp,
    faMapMarkerAlt,
    faRedoAlt,
    faLanguage,
    faMars,
    faVenus,
    faFileCsv,
    faTable,
    faMoon,
    faFingerprint,
    faExclamationCircle,
    faChartLine, faFileMedical,
    faGlobe, faGlobeAsia, faUnlockAlt, faPaste,
    faArrowCircleUp, faComment, faShare, faInfoCircle, faGrin, faSave, faFileExcel
} from '@fortawesome/free-solid-svg-icons';

import {
    faFacebook,
    faFacebookSquare,
    faGulp,
    faNodeJs,
    faSass,
    faFacebookF,
    faTwitter,
    faGooglePlusG,
    faLinkedinIn,
    faMediumM,
    faHotjar,
    faYoutube,
    faChrome,
    faTiktok
} from '@fortawesome/free-brands-svg-icons';

import {
    faHeart as farHeart,
    faLightbulb as farLightbulb,
    faObjectUngroup as farObjectUngroup,
    faPaperPlane as farPaperPlane,
    faStar as farStar,
    faImage as farImage,
    faFileArchive as farFileArchive,
    faFilePdf as farFilePdf,
    faFile as farFile,
    faFileVideo as farFileVideo,
    faFileAudio as farFileAudio,
    faFileImage as farFileImage,
    faFileCode as farFileCode,
    faClock as farClock,
    faQuestionCircle as farQuestionCircle,
    faDizzy as farDizzy,
    faGrimace as farGrimace,
    faMehBlank as farMehBlank,
    faFileExcel as farFileExcel,
    faSave as farSave,
} from '@fortawesome/free-regular-svg-icons';
import {faBuilding} from "@fortawesome/free-solid-svg-icons/faBuilding";
import {faIdBadge} from "@fortawesome/free-solid-svg-icons/faIdBadge";
import {faSun} from "@fortawesome/free-solid-svg-icons/faSun";
import {faAward} from "@fortawesome/free-solid-svg-icons/faAward";

console.log('initFa');

library.add(
    // Solid
    faChartPie,
    faCopy,
    faSearch,
    faHome,
    faBell,
    faPlay,
    faChevronRight,
    faChevronLeft,
    faChevronUp,
    faChevronDown,
    faExchangeAlt,
    faAngleRight,
    faEllipsisH,
    faCrown,
    faListAlt,
    faCheck,
    faBan,
    faStream,
    faArrowsAltV,
    faSort,
    faSortAlphaDown,
    faSortAlphaUp,
    faSortNumericDown,
    faSortNumericUp,
    faSortAmountDown,
    faSortAmountUp,
    faCheckCircle,
    faUserCircle,
    faQuestionCircle,
    faLock,
    faCircle,
    faHeart,
    faShareAlt,
    faCaretRight,
    faArrowDown,
    faArrowUp,
    faArrowLeft,
    faArrowRight,
    faPrint,
    faDollarSign,
    faLink,
    faPlus,
    faMinus,
    faUser,
    faPencilAlt,
    faCamera,
    faTimesCircle,
    faSignInAlt,
    faBook,
    faPuzzlePiece,
    faTrash,
    faAlignLeft,
    faAlignRight,
    faPlug,
    faTimes,
    faFilter,
    faExternalLinkAlt,
    faCartPlus,
    faShoppingCart,
    faStar,
    faStarHalfAlt,
    faArchive,
    faEnvelope,
    faClock,
    faStar,
    faReply,
    faLocationArrow,
    faCog,
    faPaperclip,
    faImage,
    faEllipsisV,
    faEnvelopeOpen,
    faRedo,
    faSlidersH,
    faTrashAlt,
    faFileArchive,
    faFilePdf,
    faFileAlt,
    faEye,
    faCaretUp,
    faCodeBranch,
    faExclamationTriangle,
    faListUl,
    faTh,
    faGift,
    faUsers,
    faGlobeAmericas,
    faPlusSquare,
    faCaretDown,
    faUserPlus,
    faCircleNotch,
    faExclamation,
    faThumbsUp,
    faMapMarkerAlt,
    faBuilding,
    faIdBadge,
    faRedoAlt,
    faLanguage,
    faMars,
    faVenus,
    faFileCsv,
    faTable,
    faUserCheck,
    faMoon,
    faSun,
    faFingerprint,
    faExclamationTriangle,
    faChartLine,
    faFileMedical,
    faGlobeAsia,
    faUnlockAlt,
    faPaste,
    faCopy,
    faArrowCircleUp,
    faComment,
    faShare,
    faCrown,
    faInfoCircle,
    faGrin,
    faAward,
    faExclamationCircle,
    faSave,
    faFileExcel,

    // Brand
    faFacebook,
    faFacebookF,
    faFacebookSquare,
    faGulp,
    faNodeJs,
    faSass,
    faTwitter,
    faGooglePlusG,
    faLinkedinIn,
    faMediumM,
    faHotjar,
    faYoutube,
    faChrome,

    // Regular
    farHeart,
    farLightbulb,
    farObjectUngroup,
    farPaperPlane,
    farStar,
    farImage,
    farFileArchive,
    farFilePdf,
    farFile,
    farFileVideo,
    farFileAudio,
    farFileImage,
    farFileCode,
    farClock,
    farQuestionCircle,
    farDizzy,
    farGrimace,
    farMehBlank,
    farFileExcel,
    farSave
);
