import React, {Fragment, useState, useContext} from 'react';

import WizardInput from './WizardInput';
import {CustomInput, Media} from 'reactstrap';
import FalconDropzone from '../../common/FalconDropzone';
import avatarImg from '../../../assets/img/team/avatar.png';
import {isIterableArray} from '../../../helpers/utils';
import Avatar from '../../common/Avatar';

import cloudUpload from '../../../assets/img/icons/cloud-upload.svg';
import {AuthWizardContext} from '../../../context/Context';

const AdvanceUserForm = ({register, errors}) => {
    const {handleInputChange} = useContext(AuthWizardContext);

    return (
        <Fragment>
            <WizardInput
                label="회사명"
                placeholder="회사명"
                name="company_name"
                id="company_name"
                onChange={({target}) => {
                    handleInputChange(target);
                }}
                innerRef={register({
                    required: '필수 입력 항목입니다.',
                })}
                errors={errors}
            />
            <WizardInput
                label="담당자명"
                placeholder="담당자명"
                name="manager_name"
                id="manager_name"
                onChange={({target}) => {
                    handleInputChange(target);
                }}
                innerRef={register({
                    required: '필수 입력 항목입니다.',
                })}
                errors={errors}
            />
            <WizardInput
                type="number"
                label="담당자 연락처"
                placeholder="01012345678"
                name="phone_number"
                id="phone_number"
                onChange={({target}) => {
                    handleInputChange(target);
                }}
                className="input-spin-none"
                innerRef={register({
                    required: '필수 입력 항목입니다.',
                    pattern: {
                        value: /^\d{2,3}\d{3,4}\d{4}$/,
                        message: '유효한 전화번호 형식이 아닙니다. - 없이 입력해주십시오.'
                    },
                })}
                errors={errors}
            />
        </Fragment>
    );
};

export default AdvanceUserForm;
