export const faXiao = {
    // Use a prefix like 'fac' that doesn't conflict with a prefix in the standard Font Awesome styles
    // (So avoid fab, fal, fas, far, fa)
    prefix: 'fac',
    iconName: 'xiao', // Any name you like
    icon: [
        512, // width
        512, // height
        [], // ligatures
        null, // unicode (if relevant)
        "M301.8,0h-90.9c-0.5,0-1,0.2-1.4,0.6C209.2,1,209,1.5,209,2c0,23-0.7,244.7-0.7,356.6v56.3c0.4,4.5-1.3,8.9-4.6,12c-3.3,3.1-7.8,4.5-12.2,3.9h-44.5c-2.4,0-2.6,0-2.4,2.8c0.2,2.8,28,64.3,33.4,76.4c0.5,1.2,1.8,2,3.1,2c9.4,0,46.2,0.6,56.5-0.8c9.2-1,18.1-3.6,26.3-7.8c17.9-9.7,30.9-26.5,35.8-46.2c3.1-10.5,4.6-21.3,4.5-32.3c0-69.2,0-138.5,0-207.8c0-66.2-0.4-197.4-0.4-214.9c0.1-0.5-0.1-1.1-0.5-1.5S302.4,0,301.8,0L301.8,0z M486.7,353.4c-5.4-11.5-8.1-24-8.1-36.7c0-5.8-0.8-11.6-1.3-17.3c-0.7-9-1.4-18-2.1-26.9c-0.6-8.9-1.4-19.3-2.2-29c-0.8-9.7-1.5-19.1-2.2-28.6c-0.7-9.6-1.4-18.5-2.1-27.5c-0.8-9-1.4-17.6-2.1-26.4c-0.7-8.8-1.4-19.4-2.1-29.1c-0.1-1.7-0.2-3.3-0.6-5c0-0.6-3.2-0.4-4.6-0.4h-89.4c-1.8,0-2,0-1.9,2c0.5,5.9,0.9,11.8,1.4,17.7l3.1,39c0.8,10.1,1.4,20.2,2.3,30.3c1.1,13.8,2.1,27.6,3.2,41.6c1.2,15.8,2.4,31.5,3.6,47.3c0.6,6.9,1,13.3,1.8,19.9c1.9,15,4.7,29.8,8.5,44.4c4.9,20.3,11.8,40,20.6,58.9c6.1,13,13.6,25.2,22.3,36.6c0.9,1.1,2.2,3.1,2.9,3c0.7-0.1,0.4-0.5,0.8-1.2c5.7-11.5,44.8-99.9,47.7-105.5c2.5-4.3,2.1-3.4,0.6-6.8L486.7,353.4z M142.3,126.4H52.9c-1.4-0.1-2.7-0.1-4.1,0c-0.5,0-0.6,0-0.6,0.4c-0.1,0.4-0.3,3.2-0.5,4.8c-0.8,9.7-1.4,19.4-2.1,29.1c-0.7,9.7-1.4,17.6-2.1,26.4c-0.7,8.8-1.4,18.5-2.1,27.5c-0.8,9-1.5,19.1-2.2,28.6c-0.7,9.6-1.4,19.3-2.2,29l-2.1,26.9c-0.5,5.8-1,11.6-1.3,17.3c0,12.7-2.7,25.2-8.1,36.7c-1.6,3.4-2,2.5,0,6.9c2.8,5.5,41.3,92,47.5,105.1c0.4,1,0.6,1.2,1,1.2c0.3,0,2-1.5,2.8-2.6c8.8-11.3,16.3-23.6,22.4-36.6c8.8-18.9,15.8-38.6,20.6-58.9c3.8-14.6,6.6-29.4,8.5-44.4c0.8-6.9,1.2-13.3,1.8-19.9c1.2-15.8,2.4-31.5,3.6-47.3c1-13.8,2.2-27.5,3.2-41.6c0.8-10.1,1.5-20.2,2.3-30.3l3.1-39c0.5-5.9,0.9-11.8,1.4-17.7C144.2,126.8,144,126.6,142.3,126.4L142.3,126.4z"
    ]
}