import React from 'react';
import {Col, Row} from 'reactstrap';
import Section from "../common/Section";


const Footer = ({landing}) => {
    const footer = <footer>
            <Row className="justify-content-between text-center fs--n1 mt-4 mb-3 footer-center">
                <Col sm="auto" className="text-sm-center text-lg-left">
                    <div className=''>
                        <div className='d-inline-block pr-2 border-right'>
                            (주) 와이톤
                        </div>
                        <div className='d-inline-block px-2 border-sm-right'>
                            대표 전화: 02-6203-0416
                        </div>
                        <div className='d-inline-block px-2'>
                            문의: contact@y-tone.co.kr
                        </div>
                    </div>
                    <div className='d-sm-block d-none'>
                        <div className='d-inline-block pr-md-2 border-md-right'>
                            주소: 서울특별시 강남구 선릉로135길 4, 엔빌딩 5층
                        </div>
                        <div className='d-inline-block px-2'>
                            사업자 등록번호: 574-81-01528
                        </div>
                    </div>
                    <div className='d-sm-block d-none'>
                        <div className='d-inline-block pr-2 border-sm-right'>
                            대표자: 박강웅, 홍승표, 백찬혁
                        </div>
                        <div className='d-inline-block px-2'>
                            개인정보 담당자: Kang Woong Park
                        </div>
                    </div>
                    <div>&copy; {new Date().getFullYear()} <a href="http://y-tone.co.kr" target='_blank' style={landing && {color: '#d0b8ff'}}>Y-TONE</a>.
                        All rights reserved.
                    </div>
                </Col>
            </Row>
        </footer>

    if (landing) {
        return (
            <Section className='py-3 bg-1000 text-300'>
                {footer}
            </Section>
        );
    } else {
        return footer
    }
};

export default Footer;
