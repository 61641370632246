import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardFooter, CardImg} from 'reactstrap';
import Masonry from "react-masonry-css";
import Avatar from "../common/Avatar";
import heart from '../../assets/img/icons/xhs_heart.png'
import xhsVideo from '../../assets/img/icons/xhs_video.png'
import xhsImage from '../../assets/img/icons/xhs_image.png'
import xhsBack from '../../assets/img/icons/xhs_back.png'
import xhsClear from '../../assets/img/icons/xhs_clear.png'
import xhsSearch from '../../assets/img/icons/xhs_search.png'
import xhsPlay from '../../assets/img/icons/xhs_play.png'
import xhsBlank from '../../assets/img/icons/xhs_blank.png'
import useQuery from "../../hooks/useQuery";
import OnImagesLoaded from 'react-on-images-loaded';
import {getRandomInt} from "../../helpers/utils";

const SearchUI = () => {
    const query = useQuery();
    const data = JSON.parse(query.get('data') || []);
    const keyword = query.get('keyword') || '키워드';
    const [highlight, setHighlight] = useState(null);
    useEffect(() => {
        window.ChannelIO('hideChannelButton');
    }, []);

    const truncateNumber = (number) => {
        if (number < 10000) {
            return number
        }
        return Math.round(number / 100) / 10 + 'k';
    };

    const WIDTH = '442px';

    const setRef = (element) => {
        if (element === null) {
            return
        }
        element.classList.add('border-primary', 'border-4x')
        setHighlight(element);
    };

    const onImagesLoad = () => {
        setTimeout(() => {
            window.scrollTo({top: highlight?.offsetTop - getRandomInt(250, 350)});
        }, 500);
    };

    return (
        <div className='container p-0'
             style={{width: WIDTH, height: '879px', backgroundColor: '#FAFAFA'}}>
            <div className='bg-white position-fixed z-index-10' style={{width: WIDTH}}>
                <div className='d-flex align-items-center pt-2 mb-3'>
                    <div className='d-flex flex-center px-3'>
                        <img src={xhsBack} alt='back' style={{height: '20px'}}/>
                    </div>
                    <div className='d-flex justify-content-between align-items-center rounded-pill w-100 mr-3'
                         style={{height: '36px', backgroundColor: '#F5F5F5'}}>
                        <div className='d-flex align-items-center'>
                            <img className='' src={xhsSearch} alt='search'
                                 style={{height: '18px', padding: '0 8px 0 12px'}}/>
                            <span className='text-black'>{keyword}</span>
                        </div>
                        <img src={xhsClear} alt='clear' style={{height: '18px', padding: '0 8px 0 0', opacity: 0.5}}/>
                    </div>
                </div>
                <div className='row text-black'>
                    <div className='col d-flex justify-content-end'>
                        <div className='tab-underline'>
                            Posts
                        </div>
                    </div>
                    <div className='col-auto px-4' style={{opacity: '40%'}}>
                        Users
                    </div>
                    <div className='col' style={{opacity: '40%'}}>
                        Products
                    </div>
                </div>
                <div className='d-flex align-items-center'
                     style={{
                         padding: '10px 0',
                         borderBottom: '1px solid #d8e2ef6e',
                         borderTop: '1px solid #d8e2ef6e',
                         fontSize: '0.9rem'
                     }}>
                    <div className='col pl-4 d-flex justify-content-between align-items-center'>
                        <div className='text-black' style={{opacity: '80%'}}>
                            General
                        </div>
                        <div className='text-black' style={{opacity: '60%'}}>
                            Hot
                        </div>
                        <div className='text-black' style={{opacity: '60%'}}>
                            Latest
                        </div>
                    </div>
                    <div className='col-auto d-flex align-items-center' style={{borderLeft: '1px solid #d8e2ef6e'}}>
                        <div className='' style={{paddingRight: '15px'}}>
                            <span className=''>
                                <span className='mr-1 text-black' style={{opacity: '60%'}}>Video</span>
                                <img className='align-middle pb-1 pl-1'
                                     style={{width: '17px', height: '17px', opacity: '60%'}}
                                     src={xhsVideo} alt='video'/>
                            </span>
                        </div>
                        <div className='' style={{paddingLeft: '15px', borderLeft: '1px solid #d8e2ef6e'}}>
                            <span className=''>
                                <span className='mr-1 text-black' style={{opacity: '60%'}}>Image</span>
                                <img className='align-middle pb-1 pl-1 '
                                     style={{width: '17px', height: '17px', opacity: '60%'}}
                                     src={xhsImage} alt='video'/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='' style={{paddingTop: '139.59px'}}>
                <OnImagesLoaded onLoaded={onImagesLoad}>
                    <Masonry className='masonry-grid-xhs' columnClassName='masonry-grid_column-xhs'
                             style={{padding: '0 6px 0 6px',}}
                             breakpointCols={{default: 2}}>
                        {data.map(note => {
                            return <Card innerRef={note.rank ? setRef : undefined} className='shadow-none mb-0'
                                         style={{marginTop: '6px', opacity: !!note.rank ? 1 : 0.9}} key={note.id}>
                                <CardImg
                                    style={{maxHeight: '260px'}}
                                    className='fit-cover'
                                    src={note.image}
                                    onError={(e) => {
                                        e.target.src = note.image;
                                    }}
                                    top/>
                                {note.video && <img className='position-absolute' src={xhsPlay} alt='play'
                                                    style={{top: '12px', right: '12px', width: '25px', opacity: 0.9}}/>}

                                <CardBody className='font-weight-medium text-overflow-ellipsis pb-0'
                                          style={{padding: '12px 14px', color: '#343434'}}>
                                    {note.title}
                                </CardBody>
                                <CardFooter className='' style={{padding: '12px 14px', color: '#6D6D6D'}}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='d-flex align-items-center'>
                                            <Avatar className='align-middle' style={{width: '22px', height: '22px'}}
                                                    onError={(e) => {
                                                        e.target.src = note.user_image;
                                                    }}
                                                    src={note.user_image}/>
                                            <div className='align-middle fs--1 ml-2 css-truncate css-truncate-overflow'
                                                 style={{width: '100px'}}>
                                                {note.nickname}
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <img style={{width: '16px', height: '14px'}} src={heart} alt='heart'/>
                                            <div className='align-middle fs--1 ml-1'>
                                                {truncateNumber(note.liked_count)}
                                            </div>
                                        </div>
                                    </div>
                                </CardFooter>
                            </Card>
                        })}
                    </Masonry>
                </OnImagesLoaded>
            </div>

        </div>
    );
};

export default SearchUI;