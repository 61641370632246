import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import NotFoundEmoji from "../common/NotFoundEmoji";

const Error404 = () => (
  <Card className="text-center">
    <CardBody className="p-5">
      <NotFoundEmoji/>
      <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">
        요청한 페이지를 찾을 수 없습니다.
      </p>
      <hr />
      <p className='word-break-keep-all'>
        페이지의 주소가 잘못 입력되었거나, 페이지 주소가 변경 또는 삭제되었습니다. 입력하신 페이지의 주소가 정확한지 다시 한번 확인해주시기 바랍니다.
      </p>
      <Link className="btn btn-primary btn-sm mt-3" to="/">
        <FontAwesomeIcon icon="home" className="mr-2" />
        홈으로 가기
      </Link>
    </CardBody>
  </Card>
);

export default Error404;
