import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import {CloseButton, Fade} from '../components/common/Toast';

import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';

import loadable from '@loadable/component';
import WizardLayout from '../components/findy-auth/wizard/WizardLayout';
import SearchUI from "../components/xhs-search-ui/SearchUI";

const Landing = loadable(() => import('../components/landing/Landing'));
const AuthCardRoutes = loadable(() => import('../components/findy-auth/AuthCardRoutes'));

const Layout = () => {
    useEffect(() => {
        Landing.preload();
        AuthCardRoutes.preload();
    }, []);

    return (
            <Router fallback={<span/>}>
                <Switch>
                    <Route path="/" exact component={Landing}/>
                    <Route path="/auth/register" component={WizardLayout}/>
                    <Route path="/auth/" component={AuthCardRoutes}/>
                    <Route path="/errors" component={ErrorLayout}/>
                    <Route path="/ui/search" component={SearchUI}/>
                    <Route component={DashboardLayout}/>
                </Switch>
                <ToastContainer transition={Fade} closeButton={<CloseButton/>} position={toast.POSITION.BOTTOM_LEFT}/>
            </Router>
        );

    // if (!(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
    //     // dev
    //     return (
    //         <Router fallback={<span/>}>
    //             <Switch>
    //                 <Route path="/" exact component={Landing}/>
    //                 <Route path="/auth/register" component={WizardLayout}/>
    //                 <Route path="/auth/" component={AuthCardRoutes}/>
    //                 <Route path="/errors" component={ErrorLayout}/>
    //                 <Route component={DashboardLayout}/>
    //             </Switch>
    //             <ToastContainer transition={Fade} closeButton={<CloseButton/>} position={toast.POSITION.BOTTOM_LEFT}/>
    //         </Router>
    //     );
    // } else {
    //     // prod: 에러페이지
    //     return (
    //         <Router fallback={<span/>}>
    //             <Switch>
    //                 <Route path="/" component={Maintenance}/>
    //             </Switch>
    //         </Router>
    //     );
    // }
};

export default Layout;
