import { createContext } from 'react';

const AppContext = createContext({
  isFluid: false,
  isRTL: false,
  isDark: false,
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  cartCount: 0
});

export const EmailContext = createContext({ emails: [] });

export const ProductContext = createContext({ products: [] });

export const InfluencerContext = createContext({ influencers: [] });  // added

export const InfluencerDetailContext = createContext({});  // added

export const NoteRequestContext = createContext({});  // added

export const CampaignRequestContext = createContext({});  // added

export const BrandAnalysisContext = createContext({});  // added

export const KeywordAnalysisContext = createContext({});  // added

export const CampaignDashboardContext = createContext({});  // added

export const FeedContext = createContext({ feeds: [] });

export const AuthWizardContext = createContext({ user: {} });

export const AuthContext = createContext({});

export default AppContext;
