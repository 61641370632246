import React, {Fragment} from 'react';
import {Button, UncontrolledTooltip} from 'reactstrap';

const ToggleButton = ({}) => {
    return (
        <Fragment>
            <div className="toggle-icon-wrapper">
                <div
                    className="navbar-vertical-toggle border-0"
                >
                    <span className="navbar-toggle-icon">
                      <span className="toggle-line"/>
                    </span>
                </div>
            </div>
        </Fragment>
    )
};

export default ToggleButton;
