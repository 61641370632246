import React, { Fragment, useContext } from "react";
import { Collapse, Navbar, NavItem, Nav, NavLink } from "reactstrap";
import AppContext, { AuthContext, ProductContext } from "../../context/Context";
import Logo from "./Logo";
import { Button } from "reactstrap";
import ProfileDropdown from "./ProfileDropdown";
import NotificationDropdown from "./NotificationDropdown";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { appUrls } from "../../urls";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons/faShoppingCart";
import { useHistory } from "react-router-dom";
import { replaceURL } from "../../helpers/utils";

const NavbarTop = () => {
    const history = useHistory();
	const { showBurgerMenu, setShowBurgerMenu, cartCount } = useContext(
		AppContext
	);
	// const { shoppingCart } = useContext(ProductContext);
	const {
		authState: { user },
	} = useContext(AuthContext);

    const handleRedrictLogin = () => {
        if (history.location.pathname) {
            history.push(replaceURL(history.location.pathname));
        } else {
            history.push(appUrls().login);
        }
    }
	return (
		<Navbar
			light
			className="navbar-glass fs--1 pt-3 font-weight-semi-bold row navbar-top sticky-kit"
			expand
			style={{ height: "64px" }}
		>
			<div className="toggle-icon-wrapper d-xl-none">
				<button
					className="navbar-vertical-toggle btn btn-link d-flex align-item-center justify-content-center "
					onClick={() => setShowBurgerMenu(!showBurgerMenu)}
					id="burgerMenu"
				>
					<span className="navbar-toggle-icon">
						<span className="toggle-line" />
					</span>
				</button>
			</div>
			<Logo at="navbar-top" width={80} id="topLogo" />
			<Collapse navbar>
				<Nav navbar className="align-items-center ml-auto">
					{user && (
						<NavItem>
							<NavLink
								tag={Link}
								to={appUrls().XHSCampaignLineup}
								className={classNames("p-0", {
									"notification-indicator notification-indicator-primary notification-indicator-fill": !!cartCount,
								})}
							>
								{!!cartCount && (
									<span
										className="notification-indicator-number font-weight-extra-bold"
										style={{ fontSize: "0.1rem" }}
									>
										{cartCount}
									</span>
								)}
								<FontAwesomeIcon
									icon={faShoppingCart}
									transform="shrink-7"
									className="fs-4"
								/>
							</NavLink>
						</NavItem>
					)}
					{/*{user && <NotificationDropdown/>}*/}
					{user ? (
						<ProfileDropdown />
					) : (
						<Fragment>
							<NavItem>
								<Button
									color="link"
									size="sm"
									className="text-decoration-none text-700"
                                    onClick={handleRedrictLogin}
								>
									<span className="font-weight-semi-bold fs--1">
										로그인
									</span>
								</Button>
							</NavItem>
							<NavItem>
								<Button
									color="link"
									size="sm"
									tag={Link}
									className="px-0 text-decoration-none text-700"
									to="/auth/register/"
								>
									<span className="font-weight-semi-bold fs--1">
										회원가입
									</span>
								</Button>
							</NavItem>
						</Fragment>
					)}
				</Nav>
			</Collapse>
		</Navbar>
	);
};

export default NavbarTop;
